import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import blogStyles from "./blog.module.css"

import Container from "../components/container.js"

const Blog = ({data}) => {
	
	return (
		<div>
			<Container>
			<div className={blogStyles.bump}></div>
			<h1> <span className={blogStyles.highlight}> Activity Log </span></h1>
			<p> Collected reports on our actions to date. Currently incomplete, but we're working on it!</p>
			<p>{data.allMarkdownRemark.totalCount} Posts</p>
			<hr/>
			{data.allMarkdownRemark.edges.map(({ node }) => (
				<div key={node.id}>
					<Link
						to = {node.fields.slug}
					>
						<h2>
							{node.frontmatter.title}{" "}
							<span>
								— {node.frontmatter.date}
							</span>
						</h2>
						<p>{node.excerpt}</p>
					</Link>
					<hr/>				
				</div>
			))}
			</Container>
		</div>
	)
}

export default Blog

export const query = graphql`
  query {
    allMarkdownRemark( sort: {fields: [frontmatter___date], order: DESC}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
          	slug
          }
          excerpt
        }
      }
    }
  }
`